<template>
    <div>
        <span @click="openModal">
            <slot name="button">
                <a-button
                    flaticon
                    ghost
                    type="ui"
                    shape="circle"
                    icon="fi-rr-settings" />
            </slot>
        </span>
        <a-modal
            @cancel="closeModal"
            :visible="modalVisible">
            <div class="columns_checkbox_group flex flex-col">
                <div class="mb-2 font-semibold">
                    {{ $t('table.settings') }}
                </div>
                <div
                    v-for="column in columns"
                    :key="column.key"
                    class="mt-1 first:mt-0">
                    <template v-if="column.hiddable">
                        <a-checkbox v-model="column.visible" class="flex items-center">
                            {{ column.title }}
                        </a-checkbox>
                    </template>
                </div>
            </div>
            <template #footer>
                <div class="flex">
                    <a-button 
                        class="mr-2"
                        type="ui"
                        block
                        @click="setDefaultSettings">
                        {{ $t('table.default') }}
                    </a-button>
                    <a-button 
                        block
                        type="primary"
                        @click="submit">
                        {{ $t('table.confirm') }}
                    </a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    props: {
        defaultColumns: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            modalVisible: false,
            columns: JSON.parse(JSON.stringify(this.defaultColumns))
        }
    },
    created() {
        this.initColumns()
    },
    methods: {
        initColumns() {
            const localTableConfigs = JSON.parse(localStorage.getItem('table_configs'))
            if (localTableConfigs?.projectTasks) {
                this.columns = localTableConfigs.projectTasks
            }
        },
        openModal() {
            this.modalVisible = true
        },
        closeModal() {
            this.modalVisible = false
        },
        saveColumns() {
            const localTableConfigs = JSON.parse(localStorage.getItem('table_configs')) || {}
            localTableConfigs.projectTasks = this.columns
            localStorage.setItem('table_configs', JSON.stringify(localTableConfigs))
            this.$emit('change', this.columns)
        },
        setDefaultSettings() {
            this.columns = JSON.parse(JSON.stringify(this.defaultColumns))
        },
        submit() {
            this.saveColumns()
            this.closeModal()
        }
    },
}
</script>



<template>
    <a-table 
        :columns="visibleColumns" 
        :data-source="tableData" 
        :row-key="record => record.id || record.key"
        :expandIconColumnIndex="1"
        bordered 
        :loading="loading"
        :pagination="false"
        :rowClassName="rowClassName"
        :scroll="{ x: 1000 }"
        :locale="{
            emptyText: $t('no_data')
        }"
        @expand="expand"
        :expanded-row-keys.sync="expandedRowKeys">
        <template #settingsCell>
            <span>
                <SettingsRow 
                    @change="changeColumns" 
                    :defaultColumns="defaultColumns" />
            </span>
        </template>
        <template slot="expandIcon" slot-scope="props">
            <template v-if="props.record?.children?.length">
                <a-button 
                    flaticon 
                    :icon="getExpandIcon(props.expanded)"
                    type="link" 
                    size="small" 
                    @click="props.onExpand(props.record, $event)">
                </a-button>
            </template>
            <span></span>
        </template>


        <template slot="image-column" slot-scope="image">
            <img :src="image" />
        </template>
    </a-table>
</template>

<script>

import Vue, {h} from 'vue'
// import ActionsRow from './ActionsRow.vue';
import AddRow from './AddRow.vue';
import NameRow from './NameRow.vue';
import OperatorRow from '@/components/TableWidgets/Widgets/OperatorRow.vue';
import ProfilerRow from '@/components/TableWidgets/Widgets/ProfilerRow.vue';
import DateTimeRow from '@/components/TableWidgets/Widgets/DateTimeRow.vue';
import ActionsRow from '@/components/TableWidgets/Widgets/ActionsRow.vue';
import StatusRow from '@/components/TableWidgets/Widgets/StatusRow.vue';
import SettingsRow from './SettingsRow.vue';

export default {
    components: {
        SettingsRow
    },
    props: {
        template: {
            type: Object,
            default: null
        },
        project: {
            type: Object,
            default: null
        },
        // 
        endpoint: {
            type: String,
            default: null
        },
        params: {
            type: Object,
            default: null
        },
        tableKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            expandedRowKeys: [],
            pageSize: 15,
            page: 1,
            defaultColumns: [
                {
                    title: "",
                    dataIndex: "actions",
                    key: "actions",
                    width: 64,
                    customRender: this.renderContent,
                },
                {
                    title: "Название",
                    dataIndex: "name",
                    key: "name",
                    customRender: this.renderContent
                },
                {
                    title: "Ответственный",
                    dataIndex: "operator",
                    key: "operator",
                    customRender: this.renderContent,
                    hiddable: true,
                    visible: true,
                },
                {
                    title: "Постановщик",
                    dataIndex: "owner",
                    key: "owner",
                    width: 170,
                    customRender: this.renderContent,
                    hiddable: true,
                    visible: true,
                },
                {
                    title: "Дата начала",
                    dataIndex: "date_start_plan",
                    key: "date_start_plan",
                    width: 170,
                    customRender: this.renderContent,
                    hiddable: true,
                    visible: true,
                },
                {
                    title: "Крайний срок",
                    dataIndex: "dead_line",
                    key: "dead_line",
                    width: 170,
                    customRender: this.renderContent,
                    hiddable: true,
                    visible: true,
                },
                {
                    title: "Статус",
                    dataIndex: "status",
                    key: "status",
                    width: 170,
                    customRender: this.renderContent,
                    hiddable: true,
                    visible: true,
                },
                {
                    dataIndex: "settings",
                    key: "settings",
                    width: 72,
                    slots: { title: 'settingsCell' },
                },
            ],
            columns: []
        }
    },
    computed: {
        tableData() {
            return this.$store.state.projects.tables?.[this.tableKey]?.results
        },
        user() {
            return this.$store.state.user.user
        },
        isPublic() {
            return this.template?.is_public
        },
        canChange() {
            return this.user.id === this.template?.author?.id
        },
        visibleColumns() {
            return this.columns.filter(column => !column.hiddable || column.visible)
        }
    },
    created() {
        this.initColumns()
        this.getData()
    },
    methods: {
        expand(expanded, record) {
            if (!expanded || record.children?.length > 1) { return; }
            const params = {
                task_type: 'task,stage,milestone',
                parent: record.id
            }
            const actionPayload = { 
                endpoint: '/tasks/task/list/', 
                params, 
                record,
                tableKey: this.tableKey, 
                withActionRows: true 
            }
            this.$store.dispatch('projects/setTableRowChildren', actionPayload)
                .catch(error => {
                    this.$message.error(this.$t('Error receiving table data'))
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getData() {
            const params = {
                ...this.params,
                page: this.page,
                page_size: this.pageSize,
            }
            const actionPayload = { 
                endpoint: this.endpoint, 
                params, 
                tableKey: this.tableKey, 
                withActionRows: true 
            }
            this.loading = true

            this.$store.dispatch('projects/setTable', actionPayload)
                .catch(error => {
                    this.$message.error(this.$t('Error receiving table data'))
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        changeColumns(selectedColumns) {
            this.columns = JSON.parse(JSON.stringify(selectedColumns))
            this.columns.forEach(column => column.customRender = this.renderContent)
        },
        initColumns() {
            const localTableConfigs = JSON.parse(localStorage.getItem('table_configs')) 
            const columns = localTableConfigs?.projectTasks || JSON.parse(JSON.stringify(this.defaultColumns))
            this.changeColumns(columns)
        },
        rowClassName(record) {
            if (record.task_type === 'stage') {
                return 'special-row'
            }
            return ''
        },
        renderContent(value, row, index, column) {
            const obj = {
                children: value,
                attrs: {},
            };

            if (column.key === 'name') {
                if (!row.is_action) {
                    return {
                        children: h(NameRow, {
                            props: { record: row, template: this.template?.id },
                        }),
                    }
                }
                return {
                    children: h(AddRow, {
                        props: { record: row, project: this.project, paddingLeft: 64 },
                    }),
                    attrs: { colSpan: this.visibleColumns.length }
                }
            }

            if (column.key === 'duration') {
                if (value) {
                    obj.children = value.replace('00:00:00', '')
                }
            }
            if (column.key === 'operator') {
                obj.children = h(OperatorRow, {
                    props: { record: row, text: row.operator },
                })
            }
            if (column.key === 'owner') {
                obj.children = h(ProfilerRow, {
                    props: { record: row, text: row[column.key] },
                })
            }

            if (column.key === 'status') {
                obj.children = h(StatusRow, {
                    props: { record: row, text: row.status },
                })
            }

            if (['date_start_plan', 'dead_line'].includes(column.key)) {
                obj.children = h(DateTimeRow, {
                    props: { record: row, text: row[column.key] },
                })
            }

            if (column.key === 'actions') {
                obj.children = h(ActionsRow, {
                    props: { record: row, tableType: 'tasks' },
                })
            }
            if (row.is_action) {
                obj.attrs.colSpan = 0;
            }

            return obj;
        },
        nameRender(text, row, index) {
            if (!row.is_action) {
                return {
                    children: h(NameRow, {
                        props: { record: row, template: this.template?.id },
                    }),
                }
            }
            return {
                children: h(AddRow, {
                    props: { record: row, project: this.project, paddingLeft: 64 },
                }),
                attrs: { colSpan: this.columns.length-1 }
            }
        },
        getExpandIcon(expanded) {
            return expanded ? 'fi-rr-cross-circle' : 'fi-rr-add'
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .ant-table-row {
        td[colspan] {
            padding: 8px 16px;
        }
    }

    .special-row {
        background: var(--primaryHover);
    }
}
</style>